import React, { forwardRef } from "react";
import { Form, FormBuilder } from "@redriver/cinnamon";
import { AccordionHelper } from "components/displays";
import PenSvg from "features/../../../shared/assets/pen.svg";
import SaveSvg from "features/../../../shared/assets/save.svg";
import { IconButton } from "features/../../../shared/components/buttons";
import { Button } from "semantic-ui-react";
import { getFunderDocumentSku, setFunderDocumentSku } from "../../actions";

const FunderDocumentSkuSettings = forwardRef(
  ({ settingsId, collapsed, toggleVisibility }, ref) => (
    <div ref={ref}>
      <AccordionHelper
        title="Funder Document SKU"
        collapsed={collapsed}
        toggleVisibility={toggleVisibility}
      >
        {settingsId && (
          <FormBuilder
            initialReadOnly
            loadAction={getFunderDocumentSku}
            loadParams={settingsId}
            submitAction={setFunderDocumentSku}
            submitParams={{ settingsId }}
            renderForm={(formProps, state, events) => (
              <Form {...formProps}>
                <div className="header">
                  <div className="actions form-header">
                    {!state.readOnly && (
                      <Button
                        primary
                        className="cancel-title-action"
                        content="Cancel"
                        onClick={events.onReset}
                      />
                    )}
                    <IconButton
                      primary
                      icon={state.readOnly ? PenSvg : SaveSvg}
                      content={state.readOnly ? "Edit" : "Save"}
                      onClick={() =>
                        state.readOnly
                          ? events.onToggleReadOnly()
                          : events.onSubmit({ reset: ["readOnly"] })
                      }
                      className={
                        !state.readOnly ? "positive-title-action" : null
                      }
                    />
                  </div>
                </div>
                <Form.Input field="funderDocumentSku" />
              </Form>
            )}
          />
        )}
      </AccordionHelper>
    </div>
  ),
);

export default FunderDocumentSkuSettings;
