import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { FormBuilder, Form } from "@redriver/cinnamon";
import {
  getCustomerCrmRestriction,
  updateCustomerCrmRestriction,
} from "features/Companies/Customers/actions";

const RestrictCrmUpdates = ({ clientId }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(undefined);
  const [submitTimeout, setSubmitTimeout] = useState(undefined);
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);

  useEffect(() => {
    (async () => {
      const { success, response } = await dispatch(
        getCustomerCrmRestriction(clientId),
      );

      success && setFormData(response);
    })();
  }, [clientId, dispatch]);

  const handleIconClick = () => {
    setIsCheckboxEnabled(!isCheckboxEnabled);
  };

  return (
    <React.Fragment>
      <FormBuilder
        submitAction={() => {
          return updateCustomerCrmRestriction(formData, { clientId });
        }}
        renderForm={(formProps, state, events) => (
          <Form
            {...formProps}
            value={formData}
            readOnly={false}
            disabled={!formData}
            onChange={(field, data, applyChanges) => {
              setFormData(applyChanges(formProps.value));
              clearTimeout(submitTimeout);
              setSubmitTimeout(setTimeout(events.onSubmit, 500)); //suppress rapid toggling
            }}
          >
            <div className="checkbox-icon-container">
              <Form.Checkbox
                field="restrictCrmUrlUpdate"
                label="Stop CRM Updating Record"
                disabled={!isCheckboxEnabled}
              />
              <Icon
                name={isCheckboxEnabled ? "unlock" : "lock"}
                size="large"
                onClick={handleIconClick}
                className="disable-icon"
              />
            </div>
          </Form>
        )}
      />
    </React.Fragment>
  );
};

export default RestrictCrmUpdates;
