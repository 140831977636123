import React from "react";
import { Grid, Button, Table } from "semantic-ui-react";
import { FormBuilder, Form, Loadable, ErrorMessage } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { IconButton } from "features/../../../shared/components/buttons";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { Targets, Actions } from "constants/permissions";
import { AccordionContentPanel } from "components/displays";
import { getFunderTypeSettings, updateFunderTypeSettings } from "./actions";
import {
  FunderTypeAvailabilityLookup,
  FunderRateType,
  FmvTypes,
  FunderType,
} from "features/../../../shared/constants/enums";
import { currencyFormatter } from "components/displays/DisplayHelper";

const FunderSettings = ({
  collapsedOnLoad,
  expand,
  contract,
  settingsId,
  title,
  type,
  cssClass,
  setExpand,
}) => {
  return (
    <FormBuilder
      initialReadOnly
      loadAction={getFunderTypeSettings}
      loadParams={{ settingsId, type }}
      submitAction={updateFunderTypeSettings}
      submitParams={{ settingsId, type }}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { readOnly, submitting, loading, error },
        events,
      ) => {
        return (
          <React.Fragment>
            <Loadable loading={loading || submitting}>
              <Form {...formProps} disabled={submitting || readOnly}>
                <AccordionContentPanel
                  title={title}
                  wrapperCssClass="outline-grid funder-type-settings"
                  headerCssClass="defaults-page-title"
                  collapsedOnLoad={collapsedOnLoad}
                  expand={expand}
                  contract={contract}
                  headerContent={
                    <PermissionCheck
                      target={Targets.DefaultsRestrictions}
                      action={Actions.Edit}
                    >
                      <div className="flex">
                        {!readOnly && (
                          <Button
                            primary
                            className="cancel-title-action"
                            floated="right"
                            content="Cancel"
                            onClick={events.onReset}
                          />
                        )}
                        <IconButton
                          primary
                          icon={readOnly ? Pen : Save}
                          content={readOnly ? "Edit" : "Save"}
                          floated="right"
                          onClick={() => {
                            readOnly
                              ? events.onToggleReadOnly()
                              : events.onSubmit({ reset: ["readOnly"] }),
                              setExpand(!expand);
                          }}
                          className={!readOnly ? "positive-title-action" : null}
                        />
                      </div>
                    </PermissionCheck>
                  }
                >
                  <div className={cssClass + " padded"}>
                    <ErrorMessage error={error} />
                    <div className="flex">
                      <div className="">
                        <Grid columns={14}>
                          <Grid.Row className="row">
                            <Grid.Column width={6}>
                              <Form.Checkbox
                                field="available"
                                label="Make funding type available"
                                readOnly={readOnly}
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={1}></Grid.Column>
                            <Grid.Column width={10}>
                              <Form.RadioGroup
                                field="availability"
                                readOnly={readOnly}
                                options={FunderTypeAvailabilityLookup}
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row className="row">
                            <Grid.Column width={2}></Grid.Column>
                            <Grid.Column width={3}>
                              <Form.RadioGroup
                                field="allViewsDefault"
                                readOnly={readOnly}
                                options={[
                                  { value: false, text: "As Option" },
                                  { value: true, text: "As Default" },
                                ]}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <br />
                        <br />
                      </div>
                      <div className="display-default">
                        {"Introduction Payment Display Default"}
                        <Form.Checkbox
                          field="introductionPaymentDisplayDefault"
                          label={"Display"}
                        />
                      </div>
                    </div>
                    <div className="defaults-grid fee-struct-table">
                      <Table unstackable>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell
                              className="left-align"
                              style={{ width: "30%" }}
                            >
                              <b>
                                <span>Funder V Minimum Selection</span>
                              </b>
                            </Table.Cell>
                            <Table.Cell
                              className="left-align"
                              style={{ width: "20%" }}
                            >
                              <Form.Numeric
                                minValue={4}
                                field="vMinSelection"
                                readOnly={readOnly}
                                decimalPlaces={0}
                              />
                            </Table.Cell>
                            <Table.Cell
                              className="left-align"
                              style={{ width: "30%" }}
                            >
                              <b>
                                <span>Funder V Maximum Selection</span>
                              </b>
                            </Table.Cell>
                            <Table.Cell
                              className="left-align"
                              style={{ width: "20%" }}
                            >
                              <Form.Numeric
                                field="vMaxSelection"
                                readOnly={readOnly}
                                decimalPlaces={0}
                              />
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell className="left-align">
                              <b>
                                <span>Default Qtr Selection</span>
                              </b>
                            </Table.Cell>
                            <Table.Cell className="left-align">
                              <Form.Numeric
                                field="defaultQtrSelection"
                                readOnly={readOnly}
                                decimalPlaces={0}
                              />
                            </Table.Cell>
                            <Table.Cell className="left-align">
                              <b>Maximum Selection</b>
                            </Table.Cell>
                            <Table.Cell className="left-align">
                              <Form.Numeric
                                field="maxSelection"
                                readOnly={readOnly}
                                decimalPlaces={0}
                              />
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell className="left-align">
                              <b>
                                <span>Default Product Capital Cost Value</span>
                              </b>
                            </Table.Cell>
                            <Table.Cell className="left-align has-label">
                              <Form.Numeric
                                formatValue
                                inline
                                field="defaultProdCapCost"
                                className=""
                                subLabel={process.env.MAJOR_CURRENCY}
                                subLabelPosition="left"
                                decimalPlaces={2}
                                renderReadOnly={(props) =>
                                  currencyFormatter(props.value)
                                }
                              />
                            </Table.Cell>
                            <Table.Cell className="left-align">
                              <b>Default RRP Value</b>
                            </Table.Cell>
                            <Table.Cell className="left-align has-label">
                              <Form.Numeric
                                formatValue
                                inline
                                field="defaultRrp"
                                className=""
                                subLabel={process.env.MAJOR_CURRENCY}
                                subLabelPosition="left"
                                decimalPlaces={2}
                                renderReadOnly={(props) =>
                                  currencyFormatter(props.value)
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell className="left-align">
                              <b>Rate Type Available</b>
                            </Table.Cell>
                            <Table.Cell
                              className="left-align  no-left-padding"
                              colSpan="3"
                            >
                              <Form.CheckboxGroup
                                field="rateTypesAvailable"
                                inline
                                options={FunderRateType}
                                readOnly={readOnly}
                              />
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell className="left-align">
                              <b>
                                <span>Default Selection</span>
                              </b>
                            </Table.Cell>
                            <Table.Cell
                              className="left-align  no-left-padding"
                              colSpan="3"
                            >
                              <Form.RadioGroup
                                field="rateTypeDefault"
                                inline
                                options={FunderRateType}
                                readOnly={readOnly}
                              />
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell className="left-align">
                              <b>
                                <span>FMV Default</span>
                              </b>
                            </Table.Cell>
                            <Table.Cell
                              className="left-align  no-left-padding"
                              colSpan="3"
                            >
                              <Form.RadioGroup
                                field="fmvDefault"
                                inline
                                options={FmvTypes}
                                readOnly={readOnly}
                              />
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell className="left-align">
                              <br />
                            </Table.Cell>
                            <Table.Cell className="left-align"></Table.Cell>
                            <Table.Cell className="left-align"></Table.Cell>
                            <Table.Cell className="left-align"></Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell className="left-align">
                              <b>Share of Continuing CFA</b>
                            </Table.Cell>
                            <Table.Cell className="left-align">
                              <span>IPS</span>
                            </Table.Cell>
                            <Table.Cell className="left-align">
                              <Form.Numeric
                                formatValue
                                field="ipsShare"
                                readOnly={readOnly}
                                subLabel="%"
                                subLabelPosition="right"
                                decimalPlaces={2}
                              />
                            </Table.Cell>
                            <Table.Cell className="left-align"></Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell className="left-align"></Table.Cell>
                            <Table.Cell className="left-align">
                              <span>Funder</span>
                            </Table.Cell>
                            <Table.Cell className="left-align">
                              <Form.Numeric
                                formatValue
                                field="funderShare"
                                readOnly={readOnly}
                                subLabel="%"
                                subLabelPosition="right"
                                decimalPlaces={2}
                              />
                            </Table.Cell>
                            <Table.Cell className="left-align"></Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell className="left-align"></Table.Cell>
                            <Table.Cell className="left-align">
                              Supplier
                            </Table.Cell>
                            <Table.Cell className="left-align">
                              <Form.Numeric
                                formatValue
                                field="supplierShare"
                                readOnly={readOnly}
                                subLabel="%"
                                subLabelPosition="right"
                                decimalPlaces={2}
                              />
                            </Table.Cell>
                            <Table.Cell className="left-align"></Table.Cell>
                          </Table.Row>
                          {type === FunderType.CoFunded && (
                            <React.Fragment>
                              <Table.Row>
                                <Table.Cell className="left-align"></Table.Cell>
                                <Table.Cell className="left-align"></Table.Cell>
                                <Table.Cell className="left-align"></Table.Cell>
                                <Table.Cell className="left-align"></Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell className="left-align">
                                  <b>Split of Funding</b>
                                </Table.Cell>
                                <Table.Cell className="left-align">
                                  Supplier Co Fund %
                                </Table.Cell>
                                <Table.Cell className="left-align">
                                  <Form.Numeric
                                    formatValue
                                    field="supplierCoFund"
                                    readOnly={readOnly}
                                    subLabel="%"
                                    subLabelPosition="right"
                                    decimalPlaces={2}
                                  />
                                </Table.Cell>
                                <Table.Cell className="left-align">
                                  <Form.Checkbox
                                    field="supplierCoFundIpsOnly"
                                    readOnly={readOnly}
                                    label="IPS Only - Override Available"
                                  />
                                </Table.Cell>
                              </Table.Row>
                            </React.Fragment>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </div>
                </AccordionContentPanel>
              </Form>
            </Loadable>
          </React.Fragment>
        );
      }}
    />
  );
};

export default FunderSettings;
